import React from 'react'
import '../App.css'
import imagebannerthird from '../assets/apparel.png';
import imagebannerfourth from '../assets/sticker_2.png';
import imagebannerfifth from '../assets/poster_2.png';
import imagebannersix from '../assets/see-our-work.png';

const OurWork = () => {
    return (
        <div>
            <div class="containerthree">

                <div class="d-flex justify-content-center">
                    <a href="/work"><img class="btn btn-default click-here" src={imagebannersix} alt="Third slide" /></a>
                </div>
                <div class="row">
                    <div class="col-lg-4">
                        <img className="threecolumn" src={imagebannerthird} alt="Third slide" class="responsive-2" />
                    </div>
                    <div class="col-lg-4">
                        <img className="threecolumn" src={imagebannerfourth} alt="Fourth slide" class="responsive-2" />
                    </div>
                    <div class="col-lg-4">
                        <img className="threecolumn" src={imagebannerfifth} alt="Fifth slide" class="responsive-2" />
                    </div>
                </div>
            </div>

        </div>
    )
}

export default OurWork
