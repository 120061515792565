import React from 'react'
import assets5 from '../assets/apparel-bg.jpg';
import WSPGallery from './WSPGallery';



const MiddleWork = () => {

    const galleryImages = [
        {
            img: 'https://i.ibb.co/d68WRGK/IMG-5748.png'
        },
        {
            img: "https://i.ibb.co/5TzpKWn/Bolt-STICKER.png"
        },
        {
            img: "https://i.ibb.co/QDnHzTM/Bolt-Frame-POSTER.png"
        },
        {
            img: "https://i.ibb.co/x70RfYr/Bolt-icon-flag-mockup.png"
        },
        {
            img: "https://i.ibb.co/x5Mf2cy/Bolt-plexiglass-mockup-sm.png"
        },
        {
            img: "https://i.ibb.co/MNVSpDy/DSC03418.jpg"
        },
        {
            img: "https://i.ibb.co/ZfB4XVP/t-shirt-brush-logo.jpg"
        },
        {
            img: "https://i.ibb.co/DRdbmvn/canvas.png"
        },
        {
            img: "https://i.ibb.co/0h0SYPx/IMG-5749.png"
        }
    ]

    return (
        <div>
            <div>
                <div class="container-1">
                    <div class="col-md-12">
                        <img class="path-600" src={assets5} alt="HeroImage" />
                    </div>
                    <div class="row">
                        <div class="col-sm-12 studio">
                            <h1 class="phantom-print-shop-5">OUR WORK</h1>
                            <p class="lasting-impressions-6">This are just some of the many project Phantom Print Shop have work on.
                            </p>
                            <WSPGallery
                                galleryImages={galleryImages}
                            />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default MiddleWork
