import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../firebaseConfig';
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';

const DashboardContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '60vh',
  backgroundColor: theme.palette.background.default,
}));

const Dashboard = () => {
  const [user] = useAuthState(auth);

  return (
    <DashboardContainer>
      <Typography variant="h5" component="h1" gutterBottom>
        Welcome, {user.email}
      </Typography>
      <Typography variant="body1">
        This is your dashboard. You are logged in!
      </Typography>
    </DashboardContainer>
  );
};

export default Dashboard;
