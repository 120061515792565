import assets3 from '../assets/contact_us_header.jpg';
import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import assets4 from '../assets/concept_completion.png';
import assets5 from '../assets/custom.jpg';

const MiddleContact = () => {
    const [isSubmitted, setIsSubmitted] = useState(false);

    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_sqzx371', 'template_2wkgnel', form.current, '5cUu_VrA2UhmucuXH')
            .then((result) => {
                console.log(result.text);
                console.log("message sent")
            }, (error) => {
                console.log(error.text);
            });

        setIsSubmitted(true); // Set the form submission status to true
    };

    if (isSubmitted) {
        return <ThankYouPage />;
    }

    return (
        <div>
            <div class="container-1">

                <div class="col-md-12">
                    <img class="path-600" src={assets3} alt="ContactImage" />
                </div>

                <div class="row">
                    <div class="col-sm-8">
                        <h1 class="phantom-print-shop-3">CONTACT US</h1>
                        <p class="lasting-impressions"><strong>Don't Hesitate to Contact Us With Any Question You May Have.</strong>
                            <br></br>If you have concerns or doubts or want to know more about us, please email to <a href="mailto:ask@phantomprintshop.com"> ask@phantomprintshop.com </a>
                            and we will be answering soon. or call us to <a href="tel:+12818881784"> +1 281-888-1784</a>.
                        </p>
                        <form ref={form} onSubmit={sendEmail}>
                            <div class="input-group mb-3">
                                <input type="text" class="form-control-3" name="user_name" placeholder="Full Name*" required />
                                <input type="hidden" name="_next" value="https://phantomprintshop.com/Thanks"></input>
                                <input type="text" class="form-control-3" name="user_email" placeholder="Email*" required />
                                <input type="text" class="form-control-3" name="user_phone" placeholder="Phone*" required />
                                <div class="form-group-2">
                                    <select name="services" id="services">
                                        <option value="DTG Printing">DTG Printing</option>
                                        <option value="DTF Printing">DTF Printing</option>
                                        <option value="Embroidery">Embroidery</option>
                                        <option value="Heat Press">Heat Press</option>
                                        <option value="Large Format">Large Format</option>
                                        <option value="Printing">Printing</option>
                                        <option value="Graphic Design">Graphic Design</option>
                                        <option value="Photography">Photography</option>
                                    </select>
                                </div>
                                <div class="form-group">
                                    <textarea id="contact" placeholder="Your Message" class="form-control" name="message" rows="10" required></textarea>
                                </div>
                                <input class="path-500" type="submit" value="Send" />

                            </div>
                        </form>
                    </div>
                    <div class="col-sm-4">
                        <p class="lasting-impressions-3">6413 Allegheny St, Houston, TX 77021<br></br>
                            <br></br>
                            Hour of Operations Central Time <br></br>Monday through Sunday 10 AM to 6 PM <br></br>
                            Saturday through Sunday 10 to 6 PM
                        </p>
                    </div>

                </div>
            </div>
        </div>
    );
};
const ThankYouPage = () => {
    return (
        <div class="container-1">

        <div class="col-md-12">
          <img class="path-600" src={assets5} alt="HeroImage" />
        </div>

        <div class="row">
          <div class="col-sm-8 studio">
            <h1 class="phantom-print-shop-2">Thank you</h1>
            <p class="lasting-impressions">Thanks for signing up 
            for our newsletter or on our contact page we will be in contact with your
            order for purchase. 
            </p>
          </div>
          <div class="col-sm-4">
            <img class="path-700" src={assets4} alt="From Concept to Completion" />
          </div>

        </div>
      </div>
    );
};

export default MiddleContact
