import React from 'react'
import assets4 from '../assets/concept_completion.png';
import assets5 from '../assets/custom.jpg';


const MiddleThanks = () => {
  return (
    <div>

      <div class="container-1">

        <div class="col-md-12">
          <img class="path-600" src={assets5} alt="HeroImage" />
        </div>

        <div class="row">
          <div class="col-sm-8 studio">
            <h1 class="phantom-print-shop-2">Thank you</h1>
            <p class="lasting-impressions">Thanks for signing up 
            for our newsletter or on our contact page we will be in contact with your
            order for purchase. 
            </p>
          </div>
          <div class="col-sm-4">
            <img class="path-700" src={assets4} alt="From Concept to Completion" />
          </div>

        </div>
      </div>
    </div>
  )
}

export default MiddleThanks
