import React from 'react'
import '../App.css'
import assets5 from '../assets/Component 9 - 1.png';

const Subnav = () => {
  return (
    <div>
      <nav>
        <div class="navbar">
          <div class="container nav-container">
            <input class="checkbox" type="checkbox" name="" id="" />
            <div class="hamburger-lines">
              <span class="line line1"></span>
              <span class="line line2"></span>
              <span class="line line3"></span>
            </div>
            <div class="nav-align">
              <p ><a class="inline" href="/services">SERVICES</a></p>
              <p class="inline-right"><a class="inline" href="/studio">STUDIO</a></p>
              <p class="inline-right"><a class="inline" href="/contact">CONTACT US</a></p>
              {/* <p class="inline-right"><a class="inline" href="/login">LOGIN</a></p> */}
              <p><a class="btn btn-lg btn-primary" href="/quote">GET A QUOTE</a></p>
            </div>
            <div class="menu-items">
              <li><a class="links" href="/">HOME</a></li>
              <li>< a class="links" href="/about">ABOUT US</a></li>
              <li>< a class="links" href="/services">SERVICES</a></li>
              <li>< a class="links" href="/studio">STUDIO</a></li>
              <li>< a class="links" href="/quote">GET A QUOTE</a></li>
              <li>< a class="links" href="/work">OUR WORK</a></li>
              {/* <li>< a class="links" href="/resources">RESOURCES</a></li> */}
              <li>< a class="links" href="/blogs">BLOG</a></li>
              <li>< a class="links" href="/contact">CONTACT US</a></li>
            </div>
          </div>
        </div>
      </nav>
    </div>
  )
}

export default Subnav
