import React from 'react'
import assets3 from '../assets/services.jpg';

const MiddleServices = () => {
    return (
        <div>
            <div class="container-1">

                <div class="col-md-12">
                    <img class="path-600" src={assets3} alt="HeroImage" />
                </div>

                <div class="row">
                    <div class="col-sm-8">
                        <h1 class="phantom-print-shop-3">SERVICES</h1>
                        <p class="lasting-impressions">Phantom Print Shop is a premier print shop that offers a wide range of printing services,
                            all under one roof. With a commitment to quality and customer satisfaction, our print shop is dedicated to providing high-quality
                            printing solutions that meet the needs of businesses and individuals alike.
                        </p>
                        <p class="lasting-impressions">
                            Our print shop specializes in graphic design, apparel
                            custom design, screen printing, direct-to-garment (DTG) printing, direct-to-fabric (DTF) printing, embroidery, large format
                            printing, traditional marketing materials, photography, and videography. Whatever your printing needs may be, we have the
                            expertise and equipment to get the job done.
                        </p>
                        <p class="lasting-impressions">
                            At Phantom Print Shop, we are dedicated to providing personalized service to
                            each of our clients. Our team of skilled professionals will work closely with you to understand your unique needs and deliver
                            customized solutions that exceed your expectations. We take pride in offering high-quality printing services that are both
                            affordable and reliable, without ever sacrificing on quality.
                        </p>
                        <p class="lasting-impressions">
                            In addition to our printing services, we also provide wholesale
                            blank apparel, so you can get everything you need to create custom clothing all in one place. Whether you need t-shirts,
                            hoodies, or other apparel items, we have a wide range of options available to suit your needs.
                        </p>
                        <p class="lasting-impressions">
                            At Phantom Print Shop,
                            we are committed to providing the best printing services possible. We take pride in our work and strive to exceed our
                            clients’ expectations with every job we take on. Contact us today to learn more about our printing services and how we
                            can help you bring your vision to life.
                        </p>
                    </div>
                    <div class="col-sm-4">
                        <ul class="component-25">
                            <p class="service">Services</p>
                            <li>Apparel Printing</li>
                            <li>Blank Wholesale</li>
                            <li>Embroidery</li>
                            <li >Screen Printing </li>
                            <li >DTG (Direct to Garment)</li>
                            <li >DTF (Direct to Film)</li>
                            <li >Fulfillment Services</li>
                            <li >Promotional Goods</li>
                            <li >Design Services</li>
                            <li >Large Format Printing</li>
                            <li >Printing</li>
                            <li >Murals</li>
                            <li >Signage</li>
                            <li >Photography</li>
                        </ul>
                        <a href="/contact"><input class="path-800" type="submit" value="GET A QUOTE" /></a>
                    </div>
                    
                </div>
            </div>
        </div >
    )
}

export default MiddleServices
