import React from 'react'
import Annoucement from '../components/Annoucement'
import Navbar from '../components/Navbar'
import Newsletter from '../components/Newsletter'
import OurWork from '../components/OurWork'
import Subnav from '../components/Subnav'
import Footer from '../components/Footer'
import DesignStudioCreate from '../components/DesignStudioCreate'

const DesignStudio = () => {
  return (
    <div>
      <Annoucement />
      <Navbar />
      <Subnav />
      <DesignStudioCreate />
      <OurWork />
      <Newsletter />
      <Footer />
    </div>
  )
}

export default DesignStudio
