import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from 'firebase/auth';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAJf8IosrmwK82MYhVhYLI1Z5I3bYyW8OU",
    authDomain: "phantom-print-shop.firebaseapp.com",
    projectId: "phantom-print-shop",
    storageBucket: "phantom-print-shop.appspot.com",
    messagingSenderId: "98230629370",
    appId: "G-9HLPKBCK4B",
  };

  const app = initializeApp(firebaseConfig);

  const db = getFirestore(app);
  const storage = getStorage(app);
  
  const auth = getAuth(app);

  export { db, storage, auth };