import React from 'react'
import assets3 from '../assets/abouthero.png';
import assets4 from '../assets/concept_completion.png';

const MiddleAbout = () => {
    return (
        <div>
            <div class="container-1">

                <div class="col-md-12">
                    <img class="path-600" src={assets3} alt="HeroImage" />
                </div>

                <div class="row">
                    <div class="col-sm-8 about">
                        <h1 class="phantom-print-shop-3">ABOUT US</h1>
                        <p class="lasting-impressions">At Phantom Print Shop, our team of experienced professionals are dedicated to ensuring
                            that your project is completed to the highest standards. With our state-of-the-art printing and design technology, as
                            well as our commitment to using only the best quality materials, your project is guaranteed to turn out perfect.
                        </p>
                    </div>
                    <div class="col-sm-4">
                        <img class="path-700" src={assets4} alt="From Concept to Completion" />
                    </div>
                    <div class="col-sm-12">
                        <p class="lasting-impressions-4">We understand that our customers have different needs and budgets, and we are committed to go the extra mile to make
                            sure that your project is completed on time and on budget. We are dedicated to providing our customers with the best
                            products and services.</p>

                        <p class="lasting-impressions-2">
                            At Phantom Print Shop, our team of experts is here
                            to help you make your ideas come to life. With our quality products, experience, and commitment to
                            customer service, we are sure to make your project unforgettable. Get started today and let us help
                            you bring your ideas to life! Our customer service representatives are available to assist you with
                            any questions or concerns you may have via phone or email. Just call  <a href="tel:+12818881784"> +1 281-888-1784</a> or email us at
                            <a href="mailto:ask@phantomprintshop.com"> ask@phantomprintshop.com</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MiddleAbout
