import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route} from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import Blogs from "./pages/Blogs";
import Services from "./pages/Services";
import Studio from "./pages/Studio";
import NoPage from "./pages/NoPage";
import Quote from "./pages/Quote";
import About from "./pages/About";
import DesignStudio from "./pages/DesignStudio";
import Work from "./pages/Work";
import Resources from "./pages/Resources";
import Contact from "./pages/Contact";
import Terms from "./pages/Terms";
import Faqs from "./pages/Faqs";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Dashboard from "./pages/Dashboard";
import Thanks from "./pages/Thanks";


export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="blogs" element={<Blogs />} />
          <Route path="studio" element={<Studio />} />
          <Route path="services" element={<Services />} />
          <Route path="about" element={<About />} />
          <Route path="thanks" element={<Thanks/>} />
          <Route path="quote" element={<Quote />} />
          <Route path="work" element={<Work />} />
          <Route path="contact" element={<Contact />} />
          <Route path="designstudio" element={<DesignStudio />} />
          <Route path="faqs" element={<Faqs />} />
          <Route path="resources" element={<Resources />} />
          <Route path="terms" element={<Terms/>} />
          <Route path="*" element={<NoPage />} />
          <Route path="login" element={<Login/>} />
          <Route path="register" element={<Register/>} />
          <Route path="dashboard" element={<Dashboard/>} />

        </Route>
      </Routes>
    </BrowserRouter>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));