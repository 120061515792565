import React from 'react'
import '../App.css'
import assets from '../assets/Component 55 - 1.png';
import assets2 from '../assets/Component 56 - 1.png';

const Annoucement = () => {
    return (
        <div class="component-container">
            <div className="component-60-2">
                <p className="heart-shaped-stickers">
                    
                </p>
            </div>

            <div className="component-1-94">
                <div className="phone phone-1">281-888-1784</div>
            </div>

            <div class="component-55-2">
                <div class="path-container">
                 <a href="https://www.instagram.com/phantomprint.shop/" target="_blank"><img class="path-200" src={assets} alt="Instagram Logo" /></a>
                 <a href="https://www.facebook.com/PhantomPrint.Shop" target="_blank"><img class="path-162" src={assets2} alt="Facebook Logo" /></a>
                 {/* <a href="#"><img class="path-163" src={assets3} alt="TikTock Logo" /></a> */}
                </div>
            </div>
        </div>
    )
}

export default Annoucement
