import React from 'react'
import assets8 from '../assets/Group 39.png';
import assets from '../assets/Instagram_icon_on.png';
import assets2 from '../assets/facebook_icon_on.png';
import assets3 from '../assets/tik_tok_on.png';


const Footer = () => {
    return (
        <div>
            <div class="container-6">
                <footer class="row row-cols-5 py-5 my-5">
                    <div class="col-5">
                        <a href="/" class="d-flex align-items-center link-dark text-decoration-none">
                            {/* <svg class="bi me-2" width="40" height="32"><use xlink:href="#bootstrap"></use></svg> */}
                            <img class="img-6" src={assets8} alt="Logo" />
                        </a>
                        <p class="text-muted-2"><strong>Phantom Print Shop LLC</strong> is a comprehensive merchandise production 
                            and design studio located in Houston, Texas. Our services include top-quality design, apparel printing, large-format printing, promotional
                            products, and fulfillment services. We offer everything you need to bring your ideas to life,
                            from start to finish.
                        </p>
                    </div>

                    <div class="col">

                    </div>

                    <div class="col">
                        <h5><strong>HOME</strong></h5>
                        <ul class="nav flex-column">
                            <li class="nav-item mb-2"><a href="/about" class="nav-link p-0 text-muted">About Us</a></li>
                            <li class="nav-item mb-2"><a href="/services" class="nav-link p-0 text-muted">Services</a></li>
                            <li class="nav-item mb-2"><a href="/studio" class="nav-link p-0 text-muted">Studio</a></li>
                            <li class="nav-item mb-2"><a href="/blogs" class="nav-link p-0 text-muted">Blog</a></li>
                        </ul>
                    </div>

                    <div class="col">
                        <h5><strong>RESOURCES</strong></h5>
                        <ul class="nav flex-column">
                            <li class="nav-item mb-2"><a href="/faqs" class="nav-link p-0 text-muted">FAQ's</a></li>
                            <li class="nav-item mb-2"><a href="/resources" class="nav-link p-0 text-muted">Privacy Policy</a></li>
                            <li class="nav-item mb-2"><a href="/Terms" class="nav-link p-0 text-muted">Terms and Conditions</a></li>
                        </ul>
                    </div>

                    <div class="col">
                        <h5><strong>CONTACT US</strong></h5>
                        <ul class="nav flex-column">
                            <li class="nav-item mb-2">6413 Allegheny St.
                                <br /> Houston, TX 77021
                                <br /> ask@phantomprintshop.com
                                <br /> Phone: 281-888-1784
                            </li>

                            <div class="component-55-2-3">
                                <div class="path-container">
                                    <a href="https://www.instagram.com/phantomprint.shop/" target="_blank"><img class="path-200" src={assets} alt="Instagram Logo" /></a>
                                    <a href="https://www.facebook.com/PhantomPrint.Shop" target="_blank"><img class="path-162" src={assets2} alt="Facebook Logo" /></a>
                                </div>
                            </div>
                        </ul>
                    </div>
                </footer>
                <hr/>
                <p class="text-center text-muted">Copyright©2023 Phantom Print Shop LLC</p>
                <p class="text-center text-muted">Website Designed by <a href="https://smartboymarketing.com/" target="_blank">Smartboy Marketing</a></p>
            </div>
        </div>
    )
}

export default Footer
