import React from 'react'
import Annoucement from '../components/Annoucement'
import Navbar from '../components/Navbar'
import Newsletter from '../components/Newsletter'
import OurWork from '../components/OurWork'
import Subnav from '../components/Subnav'
import Footer from '../components/Footer'
import DashboardComponent from '../components/DashboardComponent'

const Dashboard = () => {
  return (
    <div>

      <DashboardComponent/>

    </div>
  )
}

export default Dashboard
