import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import assets4 from '../assets/concept_completion.png';
import assets from '../assets/Instagram_icon_on.png';
import assets2 from '../assets/facebook_icon_on.png';

const Newsletter = () => {
    const [isSubmitted, setIsSubmitted] = useState(false);

    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_sec8b02', 'template_tzxszbp', form.current, '5cUu_VrA2UhmucuXH')
            .then((result) => {
                console.log(result.text);
                console.log("message sent")
            }, (error) => {
                console.log(error.text);
            });

        setIsSubmitted(true); // Set the form submission status to true
    };

    if (isSubmitted) {
        return <ThankYouPage />;
    }
    return (
        <div class="container-5">
            <p class="subscribe-to-the-news">
                Subscribe to the newsletter so you don't miss any promotion.
            </p>
            <form ref={form} onSubmit={sendEmail}>
                <div class="input-group mb-3">
                    <input type="text" class="form-control-2" name="user_name" placeholder="Enter Your Full Name*" required />
                    <input type="text" class="form-control-2" name="user_email" placeholder="Enter Your Email*" required />
                    <input type="text" class="form-control-2" placeholder="Enter Your Phone Number*" name="user_phone" required />
                    <input class="path-400" type="submit" value="Subscribe" />
                </div>
            </form>

        </div>
    )
};

const ThankYouPage = () => {
    return (
        <div class="container-5">

            <div class="row">
                <div class="col-sm-8 studio">
                    <h1 class="phantom-print-shop-2">Thank you</h1>
                    <p class="lasting-impressions">Thanks for signing up
                        for our newsletter we will be in contact with you!
                    </p>
                    <div class="component-55-2-3">
                        <div class="path-container-2">
                            <a href="https://www.instagram.com/phantomprint.shop/" target="_blank"><img class="path-200" src={assets} alt="Instagram Logo" /></a>
                            <a href="https://www.facebook.com/PhantomPrint.Shop" target="_blank"><img class="path-162" src={assets2} alt="Facebook Logo" /></a>
                        </div>
                    </div>
                </div>
                <div class="col-sm-4">
                    <img class="path-700" src={assets4} alt="From Concept to Completion" />
                </div>

            </div>
        </div>
    );
};


export default Newsletter
