import React from 'react'
import assets3 from '../assets/concept_completion.png';
import assets5 from '../assets/studio.jpg';

const MiddleStudio = () => {
    return (
        <div>
            <div class="container-1">

                <div class="col-md-12">
                    <img class="path-600" src={assets5} alt="HeroImage" />
                </div>

                <div class="row">
                    <div class="col-sm-8 studio">
                        <h1 class="phantom-print-shop-2">STUDIO</h1>
                        <p class="lasting-impressions">We offer a full suite of professional design services to help you
                            elevate your brand and take your business to the next level. Our team of
                            experienced designers is dedicated to helping you create a powerful
                            visual identity that will leave a lasting impression on your audience.
                        </p>

                        <p class="lasting-impressions">We offer a wide range of design services, including:
                        </p>

                        <p class="lasting-impressions">
                            <ul>
                                <li>Graphic Design: Our team of skilled graphic designers can help you
                                    create stunning visual designs that can be used across a variety of platforms.
                                </li>

                                <li>Logo Design and Branding: Your logo is the cornerstone of your brand,
                                    and we can help you create a logo that represents your brand's values
                                    and personality. We can also help you with branding guidelines to ensure
                                    consistency across all marketing materials.
                                </li>

                                <li>Vectorize Artwork: We can help you turn your raster images into vector
                                    files for high-quality printing and scalability.
                                </li>

                                <li>Photography: Our team of professional photographers can capture
                                    stunning images that showcase your brand in the best possible light.
                                </li>

                                <li>Custom Illustrations: We can create custom illustrations that help
                                    tell your brand's story and set you apart from your competitors.
                                </li>

                                <li>Marketing Print Collateral: From brochures and business cards to
                                    flyers and posters, we can design and print marketing materials that
                                    will leave a lasting impression on your customers.
                                </li>

                                <li>Stationary: We can design branded stationary that helps you stand out
                                    from the crowd and leave a lasting impression on your customers.
                                </li>

                                <li>Website Design and Development: Our team of web designers and
                                    developers can help you create a stunning website that not only looks
                                    great but also delivers a seamless user experience.
                                </li>

                            </ul>
                        </p>

                        <p class="lasting-impressions">At Phantom Print Shop, we understand that every business is unique, and
                            we take the time to understand your specific needs and goals before
                            beginning any design work. We work closely with you to create a design
                            that aligns with your brand's values and resonates with your target
                            audience.
                        </p>

                        <p class="lasting-impressions">In conclusion, at Phantom Print Shop, we offer a full suite of
                            professional design services to help you create a powerful visual
                            identity for your brand. Whether you need graphic design, logo design,
                            branding, vectorized artwork, photography, custom illustrations,
                            marketing print collateral, stationary, or website design and
                            development, we have you covered. Contact us today to discuss your
                            design needs and let us help you take your business to the next level.
                        </p>
                    </div>



                    <div class="col-sm-4">
                        <img class="path-700" src={assets3} alt="From Concept to Completion" />
                    </div>
                </div>

            </div>
            <div class="container-10">
                <div class="row">
                    <h1 class="phantom-print-shop-10">HOW IT WORKS</h1>
                    <div class="col-6 col-md-4">
                        <strong>CHOOSE YOUR APPAREL</strong> <br /> Browse through a huge selection of wholesale blank apparel.
                        We carry your favorite brands like Gildan, Bella +
                        Canvas, Port & Company and so much more. <br /> <br />
                        <a href="https://catalog.companycasuals.com/" target="_blank">companycasuals.com</a> <a href="https://www.4logowearables.com/" target="_blank">4logowearables.com</a>

                    </div>
                    <div class="col-6 col-md-4">
                        <strong>DESIGN YOUR ARTWORK</strong> <br />  Personalize your apparel with a custom design or text.
                        Whether you are looking for screen printing, direct to garment, or embroidery - we do it all!
                    </div>
                    <div class="col-6 col-md-4">
                        <strong>SEND US ALL THE INFO</strong> <br /> Send us all the details about your garment selection, quantity, method of printing,
                        and the design you would like on the apparel. We will take care of the rest!
                    </div>
                </div>
            </div>

        </div>
    )
}

export default MiddleStudio