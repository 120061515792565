import '../App.css'
import assets from '../assets/Group 39.png';
import data from '../data.json'
import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";


export const Navbar = () => {
  const [search, setSearch] = useState("");
  const [searchData, setSearchData] = useState([])
  const [selectedItem, setSelectedItem] = useState(-1)

  const handleChange = e => {
    setSearch(e.target.value)
  }

  const handleClose = () => {
    setSearch("");
    setSearchData([]);
    setSelectedItem(-1)
  };

  const handleKeyDown = e => {
    if (selectedItem < searchData.length) {
      if (e.key === "ArrowUp" && selectedItem > 0) {
        setSelectedItem(prev => prev - 1)
      }
      else if (e.key === "ArrowDown" && selectedItem < searchData.length - 1) {
        setSelectedItem(prev => prev + 1)
      }
      else if (e.key === "Enter" && selectedItem >= 0) {
        window.open(searchData[selectedItem].link)
      }

    } else {
      setSelectedItem(-1)
    }
  };

  useEffect(() => {
    if (search !== "") {
      // fetch(`http://api.tvmaze.com/search/shows?q=${search}`).then(res => res.json()).then(data => setSearchData(data))
      const newFilterData = data.filter(book => {
        return book.title.toLowerCase().includes(search.toLowerCase());
      });

      setSearchData(newFilterData)
    } else {
      setSearchData([])
    }
  }, [search])
  return (
    <div class="container">
      <div class="wrapper">
        <div class="left">
          <a href="/"><img class="path-161" src={assets} alt="Logo" /></a>
        </div>

        <div class="center">

          <div className="component-container-secondary">

            <div class="flex-container">
              {/* <div className="group-74">
                <div className="daily-deals">
                  DAILY DEALS &nbsp;&nbsp;&gt;
                </div>
              </div>
              <div class="item-2"><p class="ship">Free Shipping on Selected Items<br />
                <span class="red">SHOP NOW</span></p>
              </div> */}
            </div>

            <div class="splitscreen">
              <div class="left-2">
                {/* <img class="path-165" src={assets5} alt="Shopping Icon" />
                <img class="path-164" src={assets4} alt="Person Icon" /> */}
              </div>

              <div class="right-2">
                <p class="right-side"></p>
                <p class="right-side-top"></p>
              </div>

            </div>

            <div class="flex-container-2">
              {/* <div class="item-2">
                <div class="input-group rounded">

                  <input type="search"
                    class="form-control rounded"
                    placeholder="Search" aria-label="Search"
                    aria-describedby="search-addon" />

                </div>
              </div> */}

              <section className='search_section'>
                <div className='search_input_div'>

                  <input
                    type='text'
                    className='search_input'
                    placeholder='Search...'
                    autoComplete='off'
                    onChange={handleChange}
                    value={search}
                    onKeyDown={handleKeyDown}
                    class="form-control rounded"
                  />
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                  </svg>

                  {/* <div className='search_icon'>
                    {search === "" ? (
                      <SearchIcon />
                    ) : (
                      <CloseIcon onClick={handleClose} />
                    )}
                  </div>  */}
                </div>
                <div className='search_result'>
                  {
                    searchData.slice(0, 10).map((data, index) => {
                      return (
                        <a
                          href={data.link}
                          key={index}
                          target='_blank'
                          className={selectedItem === index ?
                            'search_suggestion_line active' :
                            'search_suggestion_line'} rel="noreferrer">
                          {data.title}
                        </a>
                      );
                    })
                  }

                </div>
              </section>

            </div>
          </div>

        </div>

      </div>
    </div>
  )
}

export default Navbar
