import React from 'react'
import banner from '../assets/Phantom_graffitti_v2.jpg';
import imagebanner from '../assets/Bolt_flag_header.jpg';
import imagebannerthird from '../assets/Bolt_tshirt_header.jpg';

const Slider = () => {
    return (
        <React.Fragment>
            <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <img className="d-block w-100" src={banner} alt="First slide" class="responsive" />
                    </div>
                    <div className="carousel-item">
                        <img className="d-block w-100" src={imagebanner} alt="Second slide" class="responsive" />
                        <div class="carousel-caption">
                            <h2>NEED SOME CUSTOM APPAREL?</h2>
                            <p><a class="btn btn-lg btn-primary" href="/contact">GET A QUOTE</a></p>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <img className="d-block w-100" src={imagebannerthird} alt="Third slide" class="responsive" />
                        <div class="carousel-caption">
                            <h2>NEED HELP WITH YOUR PROJECT?</h2>
                            <p><a class="btn btn-lg btn-primary" href="/contact">GET A QUOTE</a></p>
                        </div>
                    </div>
                </div>
                <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-chevron-left" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                    </svg>
                    <span className="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                    <span className="sr-only">Next</span>
                </a>

            </div>
        </React.Fragment>
    )
}

export default Slider


