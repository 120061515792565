import React from 'react'
import assets3 from '../assets/studio2.png';

const MiddleResources = () => {
    return (
        <div>
            <div class="container-1">

                <div class="col-md-12">
                    <img class="path-600" src={assets3} alt="HeroImage" />
                </div>

                <div class="row">
                    <div class="col-sm-12">
                        <h1 class="phantom-print-shop-7">Screen Printing or DTG which printing method should you choose?</h1>
                        <p class="lasting-impressions-2">
                            <p class="title">Screen printing and DTG (Direct-to-Garment) printing are two common techniques used to print
                             designs onto fabric. Here are the main differences between the two: </p>

                            <ul>
                                <li>Printing Method: Screen printing involves pushing ink through a mesh stencil onto the fabric. 
                                    Each color is printed separately using a different stencil. DTG printing, on the other hand,
                                    uses a specialized inkjet printer to print the design onto the fabric directly.
                                </li>

                                <li>Color Capability: Screen printing is known for its vibrant, opaque colors that stand out on dark fabrics. 
                                    This is because the ink sits on top of the fabric rather than being absorbed into it. With DTG printing, 
                                    the colors are absorbed into the fabric and can appear more muted, especially on darker fabrics.
                                </li>

                                <li>Design Complexity: Screen printing is better suited for simple, bold designs with solid colors, as 
                                    each color needs a separate stencil. DTG printing can print complex, detailed designs with many colors, gradients, and photographic elements.
                                </li>

                                <li>
                                    Fabric Type: Screen printing is more versatile when it comes to fabric type, as it can be used 
                                    on cotton, polyester, blends, and even some non-textile materials. DTG printing works best on 
                                    cotton and cotton blends, as the ink needs to be absorbed into the fabric fibers.                                
                                </li>

                            </ul>
                            <p class="sub-title-2">
                                   Ultimately, the choice between screen printing and DTG printing depends on the specific 
                                   project requirements, such as design complexity, print size, fabric type, and budget.
                            </p>

                            <p class="sub-title-2">
                                   Contact one of our specialists here at Phantom Print Shop for more details. 
                            </p>

                        </p>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default MiddleResources
