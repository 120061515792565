import React from 'react'
import assets3 from '../assets/Group 73.png';
import assets6 from '../assets/Group 136.png';

const Middle = () => {
    return (
        <div>
            <div class="container-20">
                <div class="row align-items-center">
                    <img class="path-300" src={assets6} alt="Diamond Yellow" />
                    <div class="col-sm-8">
                        <h1 class="phantom-print-shop">PHANTOM PRINT SHOP</h1>
                        <p class="lasting-impressions">Phantom Print Shop is a premier printing company that helps businesses and individuals make a
                            lasting impression with their ideas and messages. We offer a wide range of custom products, including screen printing, large format
                            printing, embroidery, promotional products, wholesale blanks, graphic design, illustration, and photography services. Our team of experts
                            will work closely with you from concept to completion, ensuring that your ideas are brought to life in the most effective and profitable way
                            possible. We pride ourselves on providing personalized service to each of our clients, making sure that every product we create is unique and
                            memorable. Whether you are a creative individual or a small or large-scale business, Phantom Print Shop is here to help you stand out from the
                            crowd. Contact us today to get started on creating the perfect product for your needs.
                        </p>
                    </div>
                    <div class="col-sm-4">
                        <img class="path-100" src={assets3} alt="Bring Ideas to Life" />
                    </div>

                    <div class="container-10">
                        <div class="row">
                            <h1 class="phantom-print-shop-10">HOW IT WORKS</h1>
                            <div class="col-6 col-md-4">
                                <strong>CHOOSE YOUR APPAREL</strong> <br /> Browse through a huge selection of wholesale blank apparel.
                                We carry your favorite brands like Gildan, Bella +
                                Canvas, Port & Company and so much more. <br /> <br />
                                <a href="https://catalog.companycasuals.com/" target="_blank">companycasuals.com</a> <a href="https://www.4logowearables.com/" target="_blank">4logowearables.com</a>

                            </div>
                            <div class="col-6 col-md-4">
                                <strong>DESIGN YOUR ARTWORK</strong> <br />  Personalize your apparel with a custom design or text.
                                Whether you are looking for screen printing, direct to garment, or embroidery - we do it all!
                            </div>
                            <div class="col-6 col-md-4">
                                <strong>SEND US ALL THE INFO</strong> <br /> Send us all the details about your garment selection, quantity, method of printing,
                                and the design you would like on the apparel. We will take care of the rest!
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default Middle
