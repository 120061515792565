import React from 'react'
import Annoucement from '../components/Annoucement'
import Navbar from '../components/Navbar'
import Newsletter from '../components/Newsletter'
import OurWork from '../components/OurWork'
import Subnav from '../components/Subnav'
import Footer from '../components/Footer'
import MiddleContact from '../components/MiddleContact'

const Contact = () => {
  return (
    <div>
      <Annoucement />
      <Navbar />
      <Subnav />
      <MiddleContact/>
      <OurWork />
      <Newsletter />
      <Footer />
      
    </div>
  )
}

export default Contact
