import React from 'react'
import assets5 from '../assets/designstudio.jpg';

const DesignStudioCreate = () => {
    return (
        <div>
            <div class="container-1">

                <div class="col-md-12">
                    <img class="path-600" src={assets5} alt="HeroImage" />
                </div>

                <div class="row">
                    <div class="col-sm-12">
                        <h1 class="phantom-print-shop-6">DESIGN STUDIO</h1>
                        <p class="lasting-impressions">If you are wondering how your designs will look in certain products you can test colors, size,
                            placement and more in our online design studio. The Phantom design studio allows you to upload your artwork, create mockups and
                            help you confirm specs.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DesignStudioCreate
