import React, { useState, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import assets5 from '../assets/custom.jpg';
import { Switch } from '@mui/material';

function MyForm() {
  const [selectedOption, setSelectedOption] = useState('screenPrinting');
  const [needProduct, setNeedProduct] = useState(false);

  const handleNeedChange= (event) => {
    setNeedProduct(event.target.checked ? "Yes" : "No");
  };

  useEffect(() => {
    console.log('Selected option:', selectedOption);
  }, [selectedOption]);

  // handle option select change
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const {
    register,
    formState: { errors }
  } = useForm();

  const [formStatus, setFormStatus] = useState('');

  const fileInputRef = useRef();


  // define input elements for each option
  const inputElements = [
    {
      value: 'screenPrinting',
      inputs: () => (
        <div key="option1-inputs">
          <div className="row g-3 mt-3">
            <div className="col-md-6">
              <label htmlFor="firstName" className="form-label">First Name</label>
              <input type="text" className="form-control" id="firstName" placeholder="First Name" {...register("firstName", { required: true, maxLength: 100, })} />
              {errors.firstName && (
                <p>
                  {errors.firstName.type === 'required' && "This field is required."}
                  {errors.firstName.type === 'maxLength' && "Max Length is 100 char."}
                </p>
              )}
            </div>
            <div className="col-md-6">
              <label htmlFor="lastName" className="form-label">Last Name</label>
              <input type="text" className="form-control" id="lastName" placeholder="Last Name" {...register("lastName", { required: true, maxLength: 100, })} />
              {errors.lastName && (
                <p>
                  {errors.lastName.type === 'required' && "This field is required."}
                  {errors.lastName.type === 'maxLength' && "Max Length is 100 char."}
                </p>
              )}
            </div>
          </div>
          <div className="row g-3 mt-3">
            <div className="col-md-6">
              <label htmlFor="email" className="form-label">Email</label>
              <input type="email" className="form-control" id="email" placeholder="Email" {...register("email", { required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, })} />
              {errors.email && (
                <p>
                  {errors.email.type === 'required' && "This field is required."}
                  {errors.email.type === 'pattern' && "Invalid Email Address"}
                </p>
              )}
            </div>
            <div className="col-md-6">
              <label htmlFor="phone" className="form-label">Phone</label>
              <input type="tel" className="form-control" id="phone" placeholder="Phone" {...register("phone", { required: false })} />
            </div>
          </div>
          <div className="row g-3 mt-3">
            <div className="col-md-6">
              <label htmlFor="garments" className="form-label">What type of garments do you need?</label>
              <input type="text" className="form-control" id="garments" placeholder="T-shirt" {...register("garments", { required: false })} />
            </div>
            <div className="col-md-6">
              <label htmlFor="quantity" className="form-label">How many do you need?</label>
              <input type="number" className="form-control" id="quantity" placeholder="0 - 10" {...register("quantity", { required: false })} />
            </div>
          </div>
          <div className="row g-3 mt-3">
            <div className="col-md-6">
              <label htmlFor="color" className="form-label">What color(s) of garments do you need?</label>
              <input type="text" className="form-control" id="color" placeholder="Black" {...register("color", { required: false })} />
            </div>
            <div className="col-md-6">
              <label htmlFor="printWhere" className="form-label">Where do you need your print?</label>
              <input type="text" className="form-control" id="printWhere" placeholder="Front Chest" {...register("printLocation", { required: false })} />
            </div>
          </div>
          <div className="row g-3 mt-3">
            <div className="col-md-6">
              <label htmlFor="when" className="form-label">When do you need your order by?</label>
              <input type="text" className="form-control" id="when" placeholder="04/09/2023" {...register("when", { required: false })} />
              <p className="form-text-description">Standard turnaround time is 10 -12 days.</p>
            </div>
            <div className="col-md-6">
              <label htmlFor="graphicDesign" className="form-label">Do you need graphic design services?</label>
              <br></br>
              <span style={{ marginRight: '10px' }}>No</span>
              <Switch id="printFileSwitch" defaultChecked={needProduct === "Yes"} onChange={handleNeedChange} {...register("Need Graphics", { required: false })}/> 
              <span style={{ marginLeft: '10px' }}>Yes</span>           
              </div>
          </div>
          <div className="row g-3 mt-3">
            <div className="col-md-6">
              <label htmlFor="attachment" className="form-label">Submit Your Artwork</label>
              <input type="file" className="form-control" id="attachment" accept="image/png, image/jpeg" placeholder="Click to Upload" {...register("attachment", { required: false })} ref={fileInputRef} />
              <p className="form-text-description">If you're having trouble uploading files, please email them to ask@phantomprintshop.com</p>
            </div>
          </div>
          <div className="row g-3 mt-3">
            <div className="col-md-6">
              <label htmlFor="comments" className="form-label">Additional Comments</label>
              <textarea type="text" className="form-control" id="comments" rows="4" cols="50" placeholder="Please give a detailed description of what you would like us to create for you." {...register("comments", { required: true, maxLength: 2000, })} />
              {errors.comments && (
                <p>
                  {errors.comments.type === 'required' && "This field is required."}
                  {errors.comments.type === 'maxLength' && "Max Length is 2000 char."}
                </p>
              )}
            </div>
          </div>
          <button type="submit" className="btn btn-primary mt-4">Submit</button>
        </div>
      ),
    },
    {
      value: 'dtgPrinting',
      inputs: () => (
        <div key="option1-inputs">
          <div className="row g-3 mt-3">
            <div className="col-md-6">
              <label htmlFor="firstName" className="form-label">First Name</label>
              <input type="text" className="form-control" id="firstName" placeholder="First Name" {...register("firstName", { required: true, maxLength: 100, })} />
              {errors.firstName && (
                <p>
                  {errors.firstName.type === 'required' && "This field is required."}
                  {errors.firstName.type === 'maxLength' && "Max Length is 100 char."}
                </p>
              )}
            </div>
            <div className="col-md-6">
              <label htmlFor="lastName" className="form-label">Last Name</label>
              <input type="text" className="form-control" id="lastName" placeholder="Last Name" {...register("lastName", { required: true, maxLength: 100, })} />
              {errors.lastName && (
                <p>
                  {errors.lastName.type === 'required' && "This field is required."}
                  {errors.lastName.type === 'maxLength' && "Max Length is 100 char."}
                </p>
              )}
            </div>
          </div>
          <div className="row g-3 mt-3">
            <div className="col-md-6">
              <label htmlFor="email" className="form-label">Email</label>
              <input type="email" className="form-control" id="email" placeholder="Email" {...register("email", { required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, })} />
              {errors.email && (
                <p>
                  {errors.email.type === 'required' && "This field is required."}
                  {errors.email.type === 'pattern' && "Invalid Email Address"}
                </p>
              )}
            </div>
            <div className="col-md-6">
              <label htmlFor="phone" className="form-label">Phone</label>
              <input type="tel" className="form-control" id="phone" placeholder="Phone" {...register("phone", { required: false })} />
            </div>
          </div>
          <div className="row g-3 mt-3">
            <div className="col-md-6">
              <label htmlFor="garments" className="form-label">What type of garments do you need?</label>
              <input type="text" className="form-control" id="garments" placeholder="T-shirt" {...register("garments", { required: false })} />
            </div>
            <div className="col-md-6">
              <label htmlFor="quantity" className="form-label">How many do you need?</label>
              <input type="number" className="form-control" id="quantity" placeholder="0 - 10" {...register("quantity", { required: false })} />
            </div>
          </div>
          <div className="row g-3 mt-3">
            <div className="col-md-6">
              <label htmlFor="color" className="form-label">What color(s) of garments do you need?</label>
              <input type="text" className="form-control" id="color" placeholder="Black" {...register("color", { required: false })} />
            </div>
            <div className="col-md-6">
              <label htmlFor="printWhere" className="form-label">Where do you need your print?</label>
              <input type="text" className="form-control" id="printWhere" placeholder="Front Chest" {...register("printLocation", { required: false })} />
            </div>
          </div>
          <div className="row g-3 mt-3">
            <div className="col-md-6">
              <label htmlFor="when" className="form-label">When do you need your order by?</label>
              <input type="text" className="form-control" id="when" placeholder="04/09/2023" {...register("when", { required: false })} />
              <p className="form-text-description">Standard turnaround time is 10 -12 days.</p>
            </div>
            <div className="col-md-6">
              <label htmlFor="graphicDesign" className="form-label">Do you need graphic design services?</label>
              <br></br>
              <span style={{ marginRight: '10px' }}>No</span>
              <Switch id="printFileSwitch" defaultChecked={false} {...register("Need Graphics", { required: false })}/> 
              <span style={{ marginLeft: '10px' }}>Yes</span>           
            </div>            
          </div>
          <div className="row g-3 mt-3">
            <div className="col-md-6">
              <label htmlFor="attachment" className="form-label">Submit Your Artwork</label>
              <input type="file" className="form-control" id="attachment" placeholder="Click to Upload" {...register("attachment", { required: false })} />
              <p className="form-text-description">If you're having trouble uploading files, please email them to ask@phantomprintshop.com</p>
            </div>
          </div>
          <div className="row g-3 mt-3">
            <div className="col-md-6">
              <label htmlFor="comments" className="form-label">Additional Comments</label>
              <textarea type="text" className="form-control" id="comments" rows="4" cols="50" placeholder="Please give a detailed description of what you would like us to create for you." {...register("comments", { required: true, maxLength: 2000, })} />
              {errors.comments && (
                <p>
                  {errors.comments.type === 'required' && "This field is required."}
                  {errors.comments.type === 'maxLength' && "Max Length is 2000 char."}
                </p>
              )}
            </div>
          </div>
          <button type="submit" className="btn btn-primary mt-4">Submit</button>
        </div>
      ),
    },
    {
      value: 'dtfPrinting',
      inputs: () => (
        <div key="option1-inputs">
          <div className="row g-3 mt-3">
            <div className="col-md-6">
              <label htmlFor="firstName" className="form-label">First Name</label>
              <input type="text" className="form-control" id="firstName" placeholder="First Name" {...register("firstName", { required: true, maxLength: 100, })} />
              {errors.firstName && (
                <p>
                  {errors.firstName.type === 'required' && "This field is required."}
                  {errors.firstName.type === 'maxLength' && "Max Length is 100 char."}
                </p>
              )}
            </div>
            <div className="col-md-6">
              <label htmlFor="lastName" className="form-label">Last Name</label>
              <input type="text" className="form-control" id="lastName" placeholder="Last Name" {...register("lastName", { required: true, maxLength: 100, })} />
              {errors.lastName && (
                <p>
                  {errors.lastName.type === 'required' && "This field is required."}
                  {errors.lastName.type === 'maxLength' && "Max Length is 100 char."}
                </p>
              )}
            </div>
          </div>
          <div className="row g-3 mt-3">
            <div className="col-md-6">
              <label htmlFor="email" className="form-label">Email</label>
              <input type="email" className="form-control" id="email" placeholder="Email" {...register("email", { required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, })} />
              {errors.email && (
                <p>
                  {errors.email.type === 'required' && "This field is required."}
                  {errors.email.type === 'pattern' && "Invalid Email Address"}
                </p>
              )}
            </div>
            <div className="col-md-6">
              <label htmlFor="phone" className="form-label">Phone</label>
              <input type="tel" className="form-control" id="phone" placeholder="Phone" {...register("phone", { required: false })} />
            </div>
          </div>
          <div className="row g-3 mt-3">
            <div className="col-md-6">
              <label htmlFor="garments" className="form-label">What type of garments do you need?</label>
              <input type="text" className="form-control" id="garments" placeholder="T-shirt" {...register("garments", { required: false })} />
            </div>
            <div className="col-md-6">
              <label htmlFor="quantity" className="form-label">How many do you need?</label>
              <input type="number" className="form-control" id="quantity" placeholder="0 - 10" {...register("quantity", { required: false })} />
            </div>
          </div>
          <div className="row g-3 mt-3">
            <div className="col-md-6">
              <label htmlFor="color" className="form-label">What color(s) of garments do you need?</label>
              <input type="text" className="form-control" id="color" placeholder="Black" {...register("color", { required: false })} />
            </div>
            <div className="col-md-6">
              <label htmlFor="printWhere" className="form-label">Where do you need your print?</label>
              <input type="text" className="form-control" id="printWhere" placeholder="Front Chest" {...register("printLocation", { required: false })} />
            </div>
          </div>
          <div className="row g-3 mt-3">
            <div className="col-md-6">
              <label htmlFor="when" className="form-label">When do you need your order by?</label>
              <input type="text" className="form-control" id="when" placeholder="04/09/2023" {...register("when", { required: false })} />
              <p className="form-text-description">Standard turnaround time is 10 -12 days.</p> 
            </div>
            <div className="col-md-6">
              <label htmlFor="graphicDesign" className="form-label">Do you need graphic design services?</label>
              <br></br>
              <span style={{ marginRight: '10px' }}>No</span>
              <Switch id="printFileSwitch" defaultChecked={false} {...register("Need Graphics", { required: false })}/> 
              <span style={{ marginLeft: '10px' }}>Yes</span> 
            </div>
          </div>
          <div className="row g-3 mt-3">
            <div className="col-md-6">
              <label htmlFor="attachment" className="form-label">Submit Your Artwork</label>
              <input type="file" className="form-control" id="attachment" placeholder="Click to Upload" {...register("attachment", { required: false })} />
              <p className="form-text-description">If you're having trouble uploading files, please email them to ask@phantomprintshop.com</p>
            </div>
          </div>
          <div className="row g-3 mt-3">
            <div className="col-md-6">
              <label htmlFor="comments" className="form-label">Additional Comments</label>
              <textarea type="text" className="form-control" id="comments" rows="4" cols="50" placeholder="Please give a detailed description of what you would like us to create for you." {...register("comments", { required: true, maxLength: 2000, })} />
              {errors.comments && (
                <p>
                  {errors.comments.type === 'required' && "This field is required."}
                  {errors.comments.type === 'maxLength' && "Max Length is 2000 char."}
                </p>
              )}
            </div>
          </div>
          <button type="submit" className="btn btn-primary mt-4">Submit</button>
        </div>
      ),
    },
    {
      value: 'embroidery',
      inputs: () => (
        <div key="option1-inputs">
          <div className="row g-3 mt-3">
            <div className="col-md-6">
              <label htmlFor="firstName" className="form-label">First Name</label>
              <input type="text" className="form-control" id="firstName" placeholder="First Name" {...register("firstName", { required: true, maxLength: 100, })} />
              {errors.firstName && (
                <p>
                  {errors.firstName.type === 'required' && "This field is required."}
                  {errors.firstName.type === 'maxLength' && "Max Length is 100 char."}
                </p>
              )}
            </div>
            <div className="col-md-6">
              <label htmlFor="lastName" className="form-label">Last Name</label>
              <input type="text" className="form-control" id="lastName" placeholder="Last Name" {...register("lastName", { required: true, maxLength: 100, })} />
              {errors.lastName && (
                <p>
                  {errors.lastName.type === 'required' && "This field is required."}
                  {errors.lastName.type === 'maxLength' && "Max Length is 100 char."}
                </p>
              )}
            </div>
          </div>
          <div className="row g-3 mt-3">
            <div className="col-md-6">
              <label htmlFor="email" className="form-label">Email</label>
              <input type="email" className="form-control" id="email" placeholder="Email" {...register("email", { required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, })} />
              {errors.email && (
                <p>
                  {errors.email.type === 'required' && "This field is required."}
                  {errors.email.type === 'pattern' && "Invalid Email Address"}
                </p>
              )}
            </div>
            <div className="col-md-6">
              <label htmlFor="phone" className="form-label">Phone</label>
              <input type="tel" className="form-control" id="phone" placeholder="Phone" {...register("phone", { required: false })} />
            </div>
          </div>
          <div className="row g-3 mt-3">
            <div className="col-md-6">
              <label htmlFor="garments" className="form-label">What type of garments do you need?</label>
              <input type="text" className="form-control" id="garments" placeholder="T-shirt" {...register("garments", { required: false })} />
            </div>
            <div className="col-md-6">
              <label htmlFor="quantity" className="form-label">How many do you need?</label>
              <input type="number" className="form-control" id="quantity" placeholder="0 - 10" {...register("quantity", { required: false })} />
            </div>
          </div>
          <div className="row g-3 mt-3">
            <div className="col-md-6">
              <label htmlFor="color" className="form-label">What color(s) of garments do you need?</label>
              <input type="text" className="form-control" id="color" placeholder="Black" {...register("color", { required: false })} />
            </div>
            <div className="col-md-6">
              <label htmlFor="printWhere" className="form-label">Where do you need your print?</label>
              <input type="text" className="form-control" id="printWhere" placeholder="Front Chest" {...register("printLocation", { required: false })} />
            </div>
          </div>
          <div className="row g-3 mt-3">
            <div className="col-md-6">
              <label htmlFor="when" className="form-label">When do you need your order by?</label>
              <input type="text" className="form-control" id="when" placeholder="04/09/2023" {...register("when", { required: false })} />
              <p className="form-text-description">Standard turnaround time is 10 -12 days.</p> 
            </div>
            <div className="col-md-6">
              <label htmlFor="graphicDesign" className="form-label">Do you need graphic design services?</label>
              <br></br>
              <span style={{ marginRight: '10px' }}>No</span>
              <Switch id="printFileSwitch" defaultChecked={false} {...register("Need Graphics", { required: false })}/> 
              <span style={{ marginLeft: '10px' }}>Yes</span> 
            </div>
          </div>
          <div className="row g-3 mt-3">
            <div className="col-md-6">
              <label htmlFor="attachment" className="form-label">Submit Your Artwork</label>
              <input type="file" className="form-control" id="attachment" placeholder="Click to Upload" {...register("attachment", { required: false })} />
              <p className="form-text-description">If you're having trouble uploading files, please email them to ask@phantomprintshop.com</p>
            </div>
          </div>
          <div className="row g-3 mt-3">
            <div className="col-md-6">
              <label htmlFor="comments" className="form-label">Additional Comments</label>
              <textarea type="text" className="form-control" id="comments" rows="4" cols="50" placeholder="Please give a detailed description of what you would like us to create for you." {...register("comments", { required: true, maxLength: 2000, })} />
              {errors.comments && (
                <p>
                  {errors.comments.type === 'required' && "This field is required."}
                  {errors.comments.type === 'maxLength' && "Max Length is 2000 char."}
                </p>
              )}
            </div>
          </div>
          <button type="submit" className="btn btn-primary mt-4">Submit</button>
        </div>
      ),
    },
    {
      value: 'heatPress',
      inputs: () => (
        <div key="option1-inputs">
          <div className="row g-3 mt-3">
            <div className="col-md-6">
              <label htmlFor="firstName" className="form-label">First Name</label>
              <input type="text" className="form-control" id="firstName" placeholder="First Name" {...register("firstName", { required: true, maxLength: 100, })} />
              {errors.firstName && (
                <p>
                  {errors.firstName.type === 'required' && "This field is required."}
                  {errors.firstName.type === 'maxLength' && "Max Length is 100 char."}
                </p>
              )}
            </div>
            <div className="col-md-6">
              <label htmlFor="lastName" className="form-label">Last Name</label>
              <input type="text" className="form-control" id="lastName" placeholder="Last Name" {...register("lastName", { required: true, maxLength: 100, })} />
              {errors.lastName && (
                <p>
                  {errors.lastName.type === 'required' && "This field is required."}
                  {errors.lastName.type === 'maxLength' && "Max Length is 100 char."}
                </p>
              )}
            </div>
          </div>
          <div className="row g-3 mt-3">
            <div className="col-md-6">
              <label htmlFor="email" className="form-label">Email</label>
              <input type="email" className="form-control" id="email" placeholder="Email" {...register("email", { required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, })} />
              {errors.email && (
                <p>
                  {errors.email.type === 'required' && "This field is required."}
                  {errors.email.type === 'pattern' && "Invalid Email Address"}
                </p>
              )}
            </div>
            <div className="col-md-6">
              <label htmlFor="phone" className="form-label">Phone</label>
              <input type="tel" className="form-control" id="phone" placeholder="Phone" {...register("phone", { required: false })} />
            </div>
          </div>
          <div className="row g-3 mt-3">
            <div className="col-md-6">
              <label htmlFor="garments" className="form-label">What type of garments do you need?</label>
              <input type="text" className="form-control" id="garments" placeholder="T-shirt" {...register("garments", { required: false })} />
            </div>
            <div className="col-md-6">
              <label htmlFor="quantity" className="form-label">How many do you need?</label>
              <input type="number" className="form-control" id="quantity" placeholder="0 - 10" {...register("quantity", { required: false })} />
            </div>
          </div>
          <div className="row g-3 mt-3">
            <div className="col-md-6">
              <label htmlFor="color" className="form-label">What color(s) of garments do you need?</label>
              <input type="text" className="form-control" id="color" placeholder="Black" {...register("color", { required: false })} />
            </div>
            <div className="col-md-6">
              <label htmlFor="printWhere" className="form-label">Where do you need your print?</label>
              <input type="text" className="form-control" id="printWhere" placeholder="Front Chest" {...register("printLocation", { required: false })} />
            </div>
          </div>
          <div className="row g-3 mt-3">
            <div className="col-md-6">
              <label htmlFor="when" className="form-label">When do you need your order by?</label>
              <input type="text" className="form-control" id="when" placeholder="04/09/2023" {...register("when", { required: false })} />
              <p className="form-text-description">Standard turnaround time is 10 -12 days.</p> 
            </div>
            <div className="col-md-6">
              <label htmlFor="graphicDesign" className="form-label">Do you need graphic design services?</label>
              <br></br>
              <span style={{ marginRight: '10px' }}>No</span>
              <Switch id="printFileSwitch" defaultChecked={false} {...register("Need Graphics", { required: false })}/> 
              <span style={{ marginLeft: '10px' }}>Yes</span> 
            </div>
          </div>
          <div className="row g-3 mt-3">
            <div className="col-md-6">
              <label htmlFor="attachment" className="form-label">Submit Your Artwork</label>
              <input type="file" className="form-control" id="attachment" placeholder="Click to Upload" {...register("attachment", { required: false })} />
              <p className="form-text-description">If you're having trouble uploading files, please email them to ask@phantomprintshop.com</p>
            </div>
          </div>
          <div className="row g-3 mt-3">
            <div className="col-md-6">
              <label htmlFor="comments" className="form-label">Additional Comments</label>
              <textarea type="text" className="form-control" id="comments" rows="4" cols="50" placeholder="Please give a detailed description of what you would like us to create for you." {...register("comments", { required: true, maxLength: 2000, })} />
              {errors.comments && (
                <p>
                  {errors.comments.type === 'required' && "This field is required."}
                  {errors.comments.type === 'maxLength' && "Max Length is 2000 char."}
                </p>
              )}
            </div>
          </div>
          <button type="submit" className="btn btn-primary mt-4">Submit</button>
        </div>
      ),
    },
    {
      value: 'largeFormat',
      inputs: () => (
        <div key="option1-inputs">
          <div className="row g-3 mt-3">
            <div className="col-md-6">
              <label htmlFor="firstName" className="form-label">First Name</label>
              <input type="text" className="form-control" id="firstName" placeholder="First Name" {...register("firstName", { required: true, maxLength: 100, })} />
              {errors.firstName && (
                <p>
                  {errors.firstName.type === 'required' && "This field is required."}
                  {errors.firstName.type === 'maxLength' && "Max Length is 100 char."}
                </p>
              )}
            </div>
            <div className="col-md-6">
              <label htmlFor="lastName" className="form-label">Last Name</label>
              <input type="text" className="form-control" id="lastName" placeholder="Last Name" {...register("lastName", { required: true, maxLength: 100, })} />
              {errors.lastName && (
                <p>
                  {errors.lastName.type === 'required' && "This field is required."}
                  {errors.lastName.type === 'maxLength' && "Max Length is 100 char."}
                </p>
              )}
            </div>
          </div>
          <div className="row g-3 mt-3">
            <div className="col-md-6">
              <label htmlFor="email" className="form-label">Email</label>
              <input type="email" className="form-control" id="email" placeholder="Email" {...register("email", { required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, })} />
              {errors.email && (
                <p>
                  {errors.email.type === 'required' && "This field is required."}
                  {errors.email.type === 'pattern' && "Invalid Email Address"}
                </p>
              )}
            </div>
            <div className="col-md-6">
              <label htmlFor="phone" className="form-label">Phone</label>
              <input type="tel" className="form-control" id="phone" placeholder="Phone" {...register("phone", { required: false })} />
            </div>
          </div>
          <div className="row g-3 mt-3">
            <div className="col-md-6">
              <label htmlFor="garments" className="form-label">What type of product do you need?</label>
              <input type="text" className="form-control" id="garments" placeholder="Banner" {...register("garments", { required: false })} />
            </div>
            <div className="col-md-6">
              <label htmlFor="quantity" className="form-label">What size do you need?</label>
              <input type="number" className="form-control" id="quantity" placeholder="0 - 10" {...register("quantity", { required: false })} />
            </div>
          </div>
          <div className="row g-3 mt-3">
            <div className="col-md-6">
              <label htmlFor="color" className="form-label">What quantity do you need?</label>
              <input type="text" className="form-control" id="color" placeholder="1" {...register("color", { required: false })} />
            </div>
            <div className="col-md-6">
              <label htmlFor="printWhere" className="form-label">Do you need grommets, H stakes or laminate?</label>
              <input type="text" className="form-control" id="printWhere" placeholder="Grommets" {...register("printLocation", { required: false })} />
            </div>
          </div>
          <div className="row g-3 mt-3">
            <div className="col-md-6">
              <label htmlFor="when" className="form-label">When do you need prints by?</label>
              <input type="text" className="form-control" id="when" placeholder="04/09/2023" {...register("when", { required: false })} />
              <p className="form-text-description">Standard turnaround time is 10 -12 days.</p>
            </div>
            <div className="col-md-6">
              <label htmlFor="graphicDesign" className="form-label">Do you have a print file?</label>
              <br></br>
              <span style={{ marginRight: '10px' }}>No</span>
              <Switch id="printFileSwitch" defaultChecked={false} {...register("Need Graphics", { required: false })}/> 
              <span style={{ marginLeft: '10px' }}>Yes</span> 
            </div>
          </div>
          <div className="row g-3 mt-3">
            <div className="col-md-6">
              <label htmlFor="attachment" className="form-label">Submit Your Artwork</label>
              <input type="file" className="form-control" id="attachment" placeholder="Click to Upload" {...register("attachment", { required: false })} />
              <p className="form-text-description">If you're having trouble uploading files, please email them to ask@phantomprintshop.com</p>
            </div>
          </div>
          <div className="row g-3 mt-3">
            <div className="col-md-6">
              <label htmlFor="comments" className="form-label">Additional Comments</label>
              <textarea type="text" className="form-control" id="comments" rows="4" cols="50" placeholder="Please give a detailed description of what you would like us to create for you." {...register("comments", { required: true, maxLength: 2000, })} />
              {errors.comments && (
                <p>
                  {errors.comments.type === 'required' && "This field is required."}
                  {errors.comments.type === 'maxLength' && "Max Length is 2000 char."}
                </p>
              )}
            </div>
          </div>
          <button type="submit" className="btn btn-primary mt-4">Submit</button>
        </div>
      ),
    },
    {
      value: 'printing',
      inputs: () => (
        <div key="option1-inputs">
          <div className="row g-3 mt-3">
            <div className="col-md-6">
              <label htmlFor="firstName" className="form-label">First Name</label>
              <input type="text" className="form-control" id="firstName" placeholder="First Name" {...register("firstName", { required: true, maxLength: 100, })} />
              {errors.firstName && (
                <p>
                  {errors.firstName.type === 'required' && "This field is required."}
                  {errors.firstName.type === 'maxLength' && "Max Length is 100 char."}
                </p>
              )}
            </div>
            <div className="col-md-6">
              <label htmlFor="lastName" className="form-label">Last Name</label>
              <input type="text" className="form-control" id="lastName" placeholder="Last Name" {...register("lastName", { required: true, maxLength: 100, })} />
              {errors.lastName && (
                <p>
                  {errors.lastName.type === 'required' && "This field is required."}
                  {errors.lastName.type === 'maxLength' && "Max Length is 100 char."}
                </p>
              )}
            </div>
          </div>
          <div className="row g-3 mt-3">
            <div className="col-md-6">
              <label htmlFor="email" className="form-label">Email</label>
              <input type="email" className="form-control" id="email" placeholder="Email" {...register("email", { required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, })} />
              {errors.email && (
                <p>
                  {errors.email.type === 'required' && "This field is required."}
                  {errors.email.type === 'pattern' && "Invalid Email Address"}
                </p>
              )}
            </div>
            <div className="col-md-6">
              <label htmlFor="phone" className="form-label">Phone</label>
              <input type="tel" className="form-control" id="phone" placeholder="Phone" {...register("phone", { required: false })} />
            </div>
          </div>
          <div className="row g-3 mt-3">
            <div className="col-md-6">
              <label htmlFor="garments" className="form-label">What type of product do you need?</label>
              <input type="text" className="form-control" id="garments" placeholder="T-shirt" {...register("garments", { required: false })} />
            </div>
            <div className="col-md-6">
              <label htmlFor="quantity" className="form-label">How size do you need?</label>
              <input type="number" className="form-control" id="quantity" placeholder="6 x 4" {...register("quantity", { required: false })} />
            </div>
          </div>
          <div className="row g-3 mt-3">
            <div className="col-md-6">
              <label htmlFor="color" className="form-label">What quantity do you need?</label>
              <input type="text" className="form-control" id="color" placeholder="Black" {...register("color", { required: false })} />
            </div>
            <div className="col-md-6">
              <label htmlFor="printWhere" className="form-label">How many pages?</label>
              <input type="text" className="form-control" id="printWhere" placeholder="Front Chest" {...register("printLocation", { required: false })} />
            </div>
          </div>
          <div className="row g-3 mt-3">
            <div className="col-md-6">
              <label htmlFor="when" className="form-label">When do you need prints by?</label>
              <input type="text" className="form-control" id="when" placeholder="04/09/2023" {...register("when", { required: false })} />
              <p className="form-text-description">Standard turnaround time is 10 -12 days.</p> 
            </div>
            <div className="col-md-6">
              <label htmlFor="graphicDesign" className="form-label">Do you have a print file?</label>
              <br></br>
              <span style={{ marginRight: '10px' }}>No</span>
              <Switch id="printFileSwitch" defaultChecked={false} {...register("Need Graphics", { required: false })}/> 
              <span style={{ marginLeft: '10px' }}>Yes</span>       
            </div>
          </div>
          <div className="row g-3 mt-3">
            <div className="col-md-6">
              <label htmlFor="attachment" className="form-label">Submit Your Artwork</label>
              <input type="file" className="form-control" id="attachment" placeholder="Click to Upload" {...register("attachment", { required: false })} />
              <p className="form-text-description">If you're having trouble uploading files, please email them to ask@phantomprintshop.com</p>
            </div>
          </div>
          <div className="row g-3 mt-3">
            <div className="col-md-6">
              <label htmlFor="comments" className="form-label">Additional Comments</label>
              <textarea type="text" className="form-control" id="comments" rows="4" cols="50" placeholder="Please give a detailed description of what you would like us to create for you." {...register("comments", { required: true, maxLength: 2000, })} />
              {errors.comments && (
                <p>
                  {errors.comments.type === 'required' && "This field is required."}
                  {errors.comments.type === 'maxLength' && "Max Length is 2000 char."}
                </p>
              )}
            </div>
          </div>
          <button type="submit" className="btn btn-primary mt-4">Submit</button>
        </div>
      ),
    },
    {
      value: 'graphicDesign',
      inputs: () => (
        <div key="option1-inputs">
          <div className="row g-3 mt-3">
            <div className="col-md-6">
              <label htmlFor="firstName" className="form-label">First Name</label>
              <input type="text" className="form-control" id="firstName" placeholder="First Name" {...register("firstName", { required: true, maxLength: 100, })} />
              {errors.firstName && (
                <p>
                  {errors.firstName.type === 'required' && "This field is required."}
                  {errors.firstName.type === 'maxLength' && "Max Length is 100 char."}
                </p>
              )}
            </div>
            <div className="col-md-6">
              <label htmlFor="lastName" className="form-label">Last Name</label>
              <input type="text" className="form-control" id="lastName" placeholder="Last Name" {...register("lastName", { required: true, maxLength: 100, })} />
              {errors.lastName && (
                <p>
                  {errors.lastName.type === 'required' && "This field is required."}
                  {errors.lastName.type === 'maxLength' && "Max Length is 100 char."}
                </p>
              )}
            </div>
          </div>
          <div className="row g-3 mt-3">
            <div className="col-md-6">
              <label htmlFor="email" className="form-label">Email</label>
              <input type="email" className="form-control" id="email" placeholder="Email" {...register("email", { required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, })} />
              {errors.email && (
                <p>
                  {errors.email.type === 'required' && "This field is required."}
                  {errors.email.type === 'pattern' && "Invalid Email Address"}
                </p>
              )}
            </div>
            <div className="col-md-6">
              <label htmlFor="phone" className="form-label">Phone</label>
              <input type="tel" className="form-control" id="phone" placeholder="Phone" {...register("phone", { required: false })} />
            </div>
          </div>
          <div className="row g-3 mt-3">
            <div className="col-md-6">
              <label htmlFor="garments" className="form-label">What do you need design?</label>
              <input type="text" className="form-control" id="design" placeholder="Postcard" {...register("design", { required: false })} />
            </div>
            <div className="col-md-6">
              <label htmlFor="quantity" className="form-label">What size do you need?</label>
              <input type="text" className="form-control" id="size" placeholder="6 x 4" {...register("size", { required: false })} />
            </div>
          </div>
          <div className="row g-3 mt-3">
            <div className="col-md-6">
              <label htmlFor="color" className="form-label">What are some must-have elemnts?</label>
              <input type="text" className="form-control" id="elements" placeholder="Rainbow" {...register("elements", { required: false })} />
            </div>
            <div className="col-md-6">
              <label htmlFor="printWhere" className="form-label">Please provide an example URL</label>
              <input type="text" className="form-control" id="exampleURL" placeholder="example.com" {...register("exampleURL", { required: false })} />
            </div>
          </div>
          <div className="row g-3 mt-3">
            <div className="col-md-6">
              <label htmlFor="when" className="form-label">When do you need graphics by?</label>
              <input type="text" className="form-control" id="when" placeholder="04/09/2023" {...register("when", { required: false })} />
              <p className="form-text-description">Standard turnaround time is 10 -12 days.</p> 
            </div>
            <div className="col-md-6">
              <label htmlFor="graphicDesign" className="form-label">Do you have an idea already?</label>
              <br></br>
              <span style={{ marginRight: '10px' }}>No</span>
              <Switch id="printFileSwitch" defaultChecked={false} {...register("Need Graphics", { required: false })}/> 
              <span style={{ marginLeft: '10px' }}>Yes</span> 
            </div>
          </div>
          <div className="row g-3 mt-3">
            <div className="col-md-6">
              <label htmlFor="attachment" className="form-label">Submit Your Artwork</label>
              <input type="file" className="form-control" id="attachment" placeholder="Click to Upload" {...register("attachment", { required: false })} />
              <p className="form-text-description">If you're having trouble uploading files, please email them to ask@phantomprintshop.com</p>
            </div>
          </div>
          <div className="row g-3 mt-3">
            <div className="col-md-6">
              <label htmlFor="comments" className="form-label">Additional Comments</label>
              <textarea type="text" className="form-control" id="comments" rows="4" cols="50" placeholder="Please give a detailed description of what you would like us to create for you." {...register("comments", { required: true, maxLength: 2000, })} />
              {errors.comments && (
                <p>
                  {errors.comments.type === 'required' && "This field is required."}
                  {errors.comments.type === 'maxLength' && "Max Length is 2000 char."}
                </p>
              )}
            </div>
          </div>
          <button type="submit" className="btn btn-primary mt-4">Submit</button>
        </div>
      ),
    },
    {
      value: 'photography',
      inputs: () => (
        <div key="option1-inputs">
          <div className="row g-3 mt-3">
            <div className="col-md-6">
              <label htmlFor="firstName" className="form-label">First Name</label>
              <input type="text" className="form-control" id="firstName" placeholder="First Name" {...register("firstName", { required: true, maxLength: 100, })} />
              {errors.firstName && (
                <p>
                  {errors.firstName.type === 'required' && "This field is required."}
                  {errors.firstName.type === 'maxLength' && "Max Length is 100 char."}
                </p>
              )}
            </div>
            <div className="col-md-6">
              <label htmlFor="lastName" className="form-label">Last Name</label>
              <input type="text" className="form-control" id="lastName" placeholder="Last Name" {...register("lastName", { required: true, maxLength: 100, })} />
              {errors.lastName && (
                <p>
                  {errors.lastName.type === 'required' && "This field is required."}
                  {errors.lastName.type === 'maxLength' && "Max Length is 100 char."}
                </p>
              )}
            </div>
          </div>
          <div className="row g-3 mt-3">
            <div className="col-md-6">
              <label htmlFor="email" className="form-label">Email</label>
              <input type="email" className="form-control" id="email" placeholder="Email" {...register("email", { required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, })} />
              {errors.email && (
                <p>
                  {errors.email.type === 'required' && "This field is required."}
                  {errors.email.type === 'pattern' && "Invalid Email Address"}
                </p>
              )}
            </div>
            <div className="col-md-6">
              <label htmlFor="phone" className="form-label">Phone</label>
              <input type="tel" className="form-control" id="phone" placeholder="Phone" {...register("phone", { required: false })} />
            </div>
          </div>
          <div className="row g-3 mt-3">
            <div className="col-md-6">
              <label htmlFor="garments" className="form-label">How many photos do you need?</label>
              <input type="text" className="form-control" id="garments" placeholder="10" {...register("garments", { required: false })} />
            </div>
            <div className="col-md-6">
              <label htmlFor="quantity" className="form-label">What type of photoshoot do you need?</label>
              <input type="text" className="form-control" id="quantity" placeholder="Product or/and Lifestyle" {...register("quantity", { required: false })} />
            </div>
          </div>
          <div className="row g-3 mt-3">
            <div className="col-md-6">
              <label htmlFor="color" className="form-label">When do you need photos by?</label>
              <input type="text" className="form-control" id="color" placeholder="04/09/2023" {...register("color", { required: false })} />
              <p className="form-text-description">Standard turnaround time is 10 -12 days.</p> 
            </div>
            <div className="col-md-6">
              <label htmlFor="printWhere" className="form-label">Where do you need the photoshoot?</label>
              <input type="text" className="form-control" id="printWhere" placeholder="In-House or/and On-Location" {...register("printLocation", { required: false })} />
            </div>
          </div>
          <div className="row g-3 mt-3">
            <div className="col-md-6">
              <label htmlFor="when" className="form-label">Will you need print file?</label>
              <br></br>
              <span style={{ marginRight: '10px' }}>No</span>
              <Switch id="printFileSwitch" defaultChecked={false} {...register("Need Graphics", { required: false })}/> 
              <span style={{ marginLeft: '10px' }}>Yes</span> 
            </div>
            <div className="col-md-6">
            </div>
          </div>
          <div className="row g-3 mt-3">
            <div className="col-md-6">
              <label htmlFor="comments" className="form-label">Additional Comments</label>
              <textarea type="text" className="form-control" id="comments" rows="4" cols="50" placeholder="Please give a detailed description of what you would like us to create for you." {...register("comments", { required: true, maxLength: 2000, })} />
              {errors.comments && (
                <p>
                  {errors.comments.type === 'required' && "This field is required."}
                  {errors.comments.type === 'maxLength' && "Max Length is 2000 char."}
                </p>
              )}
            </div>
          </div>
          <button type="submit" className="btn btn-primary mt-4">Submit</button>
        </div>
      ),
    }
  ];

  // find input elements for selected option
  const selectedInputs = inputElements.find((option) => option.value === selectedOption)?.inputs?.() || null;

  return (
    <>
      <div className="row g-3 mt-3">
        <div className="col-md-6" style={{ paddingLeft: '0px' }}>
          <label htmlFor="quoteTypeSelect" className="form-label">
            Quote Type
          </label>
          <br></br>
          <select
            className="bg-white w-100 p-1 rounded"
            id="select-input quoteTypeSelect"
            value={selectedOption}
            onChange={handleOptionChange}
          >
            <option value="screenPrinting">Screen Printing</option>
            <option value="dtgPrinting">DTG Printing</option>
            <option value="dtfPrinting">DTF Printing</option>
            <option value="embroidery">Embroidery</option>
            <option value="heatPress">Heat Press</option>
            <option value="largeFormat">Large Format</option>
            <option value="printing">Printing</option>
            <option value="graphicDesign">Graphic Design</option>
            <option value="photography">Photography</option>

          </select>
        </div>
      </div>
      <form
        target="_blank"
        action="https://formsubmit.co/jessica.phantomprintshop@gmail.com"
        method="POST"
        encType="multipart/form-data" 
      >
        <input
          type="hidden"
          name="quoteTypeSelect"
          value={selectedOption}
          {...register("quoteTypeSelect", { required: false })}
        />
        <div className="row g-3 mt-3">
          <div className="col-md-12">{selectedInputs}</div>
        </div>
      </form>
      <br></br>
      <br></br>
      {formStatus === 'success' && (
        <div className="alert alert-success mt-3" role="alert">
          Form submitted successfully! We'll be in touch soon.
        </div>
      )}
      {formStatus === 'error' && (
        <div className="alert alert-danger mt-3" role="alert">
          Form submission failed. Please try again or contact us directly.
        </div>
      )}
    </>
  );
}

const MiddleQuote = () => {
  return (
    <div>
      <div class="container-1">
        <div class="col-md-12">
          <img class="path-600" src={assets5} alt="HeroImage" />
        </div>
        <div className="row">
          <div className="col-12 pt-3">
            <h1 className="phantom-print-shop-20">GET A QUOTE</h1>
            <p className="lasting-impressions-10">
              We are thrilled that you thought about Phantom Print Shop for you creative project. Please complete the form below, and one of our representatives will contact you with a quote for your custom order.
            </p>
          </div>
        </div>
        <div className="p-5" style={{ marginLeft: '20rem', marginRight: '20rem', content: '""', clear: 'both' }}>
          <h2>Customer Information</h2>
          <MyForm />
        </div>
      </div>
    </div>
  )
}

export default MiddleQuote
