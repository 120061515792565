import React from 'react'
import assets3 from '../assets/studio2.png';

const MiddleResources = () => {
    return (
        <div>
            <div class="container-1">

                <div class="col-md-12">
                    <img class="path-600" src={assets3} alt="HeroImage" />
                </div>

                <div class="row">
                    <div class="col-sm-12">
                        <h1 class="phantom-print-shop-4">PRIVACY POLICY</h1>
                        <p class="lasting-impressions-2">
                            <p class="title"> ONLINE PRIVACY POLICY AGREEMENT </p>

                            Phantom Print Shop, LLC, (Phantom), is committed to
                            keeping any and all personal information collected from those individuals that visit
                            our website and make use of our online facilities and services accurate, confidential,
                            secure and private. Our privacy policy has been designed to ensure those affiliated with
                            Phantom Print Shop, LLC of our commitment to and realization of our obligation not only to
                            meet but to exceed existing privacy standards. THEREFORE, this Privacy Policy Agreement shall
                            apply to Phantom Print Shop, LLC, and thus it shall govern any and all data collection and usage
                            thereof. Through the use of www.PhantomPrintShop.com you are herein consenting to the following data
                            procedures expressed within this agreement.


                            <p class="sub-title"> Collection of Information: </p>
                            <p class="sub-title-2">
                                This website collects various
                                types of information, such as: Information automatically collected when visiting our website, which may
                                include cookies, third party tracking technologies and server logs. We may also gather information about
                                the type of browser you are using, IP address or type of operating system to assist us in providing and
                                maintaining superior quality service.
                            </p>
                            <p class="sub-title-2">
                                It is highly recommended and suggested that you review the privacy
                                policies and statements of any website you choose to use or frequent as a means to better understand the
                                way in which other websites garner, make use of and share information that is collected.
                            </p>
                            <p class="sub-title-2">
                                Use of Information Collected Phantom Print Shop, LLC may collect and may make use of personal information to assist in the
                                operation of our website and to ensure delivery of the services you need and request. At times, we may
                                find it necessary to use personally identifiable information as a means to keep you informed of other
                                possible products and/or services that may be available to you from www.PhantomPrintShop.com. Phantom
                                Print Shop, LLC may also be in contact with you with regards to completing surveys and/or research
                                questionnaires related to your opinion of current or potential future services that may be offered.
                            </p>
                            <p class="sub-title-2">
                                Phantom Print Shop, LLC does not now, nor will it in the future, sell, rent or lease any of our customer
                                lists and/or names to any third parties.
                            </p>
                            <p class="sub-title-2">
                                Phantom Print Shop, LLC may deem it necessary to follow websites
                                and/or pages that our users may frequent in an effort to gleam what types of services and/or products may
                                be the most popular to customers or the general public.
                            </p>
                            <p class="sub-title-2">
                                Phantom Print Shop, LLC may disclose your personal
                                information, without prior notice to you, only if required to do so in accordance with applicable laws
                                and/or in a good faith belief that such action is deemed necessary or is required in an effort to:
                            </p>
                            <ul>
                                <li>Remain in conformance with any decrees, laws and/or statutes or in an effort to comply with any
                                    process which may be served upon Phantom Print Shop, LLC and/or our website;</li>

                                <li>Maintain, safeguard
                                    and/or preserve all the rights and/or property of Phantom Print Shop, LLC; and</li>

                                <li>Perform under demanding
                                    conditions in an effort to safeguard the personal safety of users of www.PhantomPrintShop,com and/or the
                                    general public.</li>
                            </ul>
                            <p class="sub-title-2">
                                Children Under Age of 13 Phantom Print Shop, LLC does not knowingly collect personal
                                identifiable information from children under the age of thirteen (13) without verifiable parental consent.
                                If it is determined that such information has been inadvertently collected on anyone under the age of
                                thirteen (13), we shall immediately take the necessary steps to ensure that such information is deleted
                                from our system's database. Anyone under the age of thirteen (13) must seek and obtain parent or guardian
                                permission to use this website.
                            </p>
                            <p class="sub-title-2">
                                Unsubscribe or Opt-Out All users and/or visitors to our website have the
                                option to discontinue receiving communication from us and/or reserve the right to discontinue receiving
                                communications by way of email or newsletters. To discontinue or unsubscribe to our website please send
                                an email that you wish to unsubscribe to <a href="mailto:ask@phantomprintshop.com"> ask@phantomprintshop.com</a>. If you wish to unsubscribe or opt-out
                                from any third party websites, you must go to that specific website to unsubscribe and/or opt-out.
                            </p>

                            <p class="sub-title-2">
                                Links to Other Web Sites Our website does contain links to affiliate and other websites.
                                Phantom Print Shop, LLC does not claim nor accept responsibility for any privacy policies,
                                practices and/or procedures of other such websites.

                                Therefore, we encourage all users and visitors to be
                                aware when they leave our website and to read the privacy statements of each and every website that
                                collects personally identifiable information. The aforementioned Privacy Policy Agreement applies only
                                and solely to the information collected by our website.
                            </p>

                            <p class="sub-title-2">
                                Security Phantom Print Shop, LLC shall endeavor
                                and shall take every precaution to maintain adequate physical, procedural and technical security with
                                respect to our offices and information storage facilities so as to prevent any loss, misuse, unauthorized
                                access, disclosure or modification of the user's personal information under our control.
                            </p>

                            <p class="sub-title-2">
                                The company also uses Secure Socket Layer (SSL) for authentication and private communications in an
                                effort to build users' trust and confidence in the internet and website use by providing simple and
                                secure access and communication of credit card and personal information.
                            </p>

                            <p class="sub-title-2">
                                Changes to Privacy Policy
                                Agreement Phantom Print Shop, LLC reserves the right to update and/or change the terms of our privacy
                                policy, and as such we will post those change to our website homepage at www.PhantomPrintShop.com, so
                                that our users and/or visitors are always aware of the type of information we collect, how it will be
                                used, and under what circumstances, if any, we may disclose such information. If at any point in time
                                Phantom Print Shop, LLC decides to make use of any personally identifiable information on file, in a
                                manner vastly different from that which was stated when this information was initially collected, the
                                user or users shall be promptly notified by email. Users at that time shall have the option as to whether
                                or not to permit the use of their information in this separate manner.
                            </p>

                            <p class="sub-title-2">
                                Acceptance of Terms Through the
                                use of this website, you are hereby accepting the terms and conditions stipulated within the aforementioned
                                Privacy Policy Agreement. If you are not in agreement with our terms and conditions, then you should
                                refrain from further use of our sites. In addition, your continued use of our website following the
                                posting of any updates or changes to our terms and conditions shall mean that you are in agreement
                                and acceptance of such changes.
                            </p>

                            <p class="sub-title-2">
                                How to Contact Us If you have any questions or concerns regarding the
                                Privacy Policy Agreement related to our website, please feel free to contact us at the following email,
                                telephone number or mailing address.
                            </p>

                            <ul>
                                <li>Email: <a href="mailto:ask@phantomprintshop.com"> ask@phantomprintshop.com</a></li>
                                <li>Telephone Number: <a href="tel:+12818881784"> +1 281-888-1784</a> </li>
                                <li> Mailing Address: Phantom Print Shop, LLC 6413 Allegheny St. Houston, Texas 77021 </li>
                            </ul>
                        </p>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default MiddleResources
