import React from 'react'
import assets3 from '../assets/studio2.png';

const MiddleFaq = () => {
    return (
        <div>
            <div class="container-1">
                <div class="col-md-12">
                    <img class="path-600" src={assets3} alt="HeroImage" />
                </div>

                <div class="row">
                    <div class="col-sm-12">
                        <h1 class="phantom-print-shop-4">FAQ'S</h1>
                        <p class="lasting-impressions-2">

                            <p class="sub-title">  What are your store hours? </p>
                            <p class="sub-title-2">
                                <ul>
                                    <li>Monday through Sunday 10 AM to 6 PM Saturday through Sunday 10 to 6 PM</li>
                                </ul>
                            </p>

                            <p class="sub-title">How long is our turnaround time?</p>
                            <ul>
                                <li>Standard time is 10 - 12 business days after receiving payment for most print methods.
                                    (not including shipping time).
                                </li>
                            </ul>
                            <p class="sub-title">
                                Do we offer expedited turnaround times?
                            </p>
                            <p class="sub-title-2">
                                <ul>
                                    <li>Yes, we are usually able to accommodate as soon as few hours' notices.</li>
                                </ul>
                            </p>

                            <p class="sub-title">
                                An additional Fee is usually applied to your invoice and the fee will
                                vary depending on how short notice the order is. What if I am looking
                                for something that I do not see on your site?
                            </p>

                            <p class="sub-title-2">
                                <ul>
                                    <li>Feel free to contact us with any questions regarding stock on
                                        products or services offered.
                                    </li>
                                </ul>
                            </p>

                            <p class="sub-title">
                                We are always expanding our inventory and list of services. What if I have a Sales Tax
                                Exemption or am a part of a Non-Profit organization?
                            </p>

                            <p class="sub-title-2">
                                <ul>
                                    <li>
                                        You can set up your Texas Sales
                                        Tax Exemption or Non-Profit with us by filling out the appropriate form. Tax exemptions
                                        and Non-Profit accounts must be made before making a payment towards the order.
                                    </li>
                                </ul>
                            </p>


                            <p class="sub-title">
                                How to contact us If you have any questions or concerns?
                            </p>

                            <p class="sub-title-2">
                                <ul>
                                    <li>
                                        Please feel free to contact us at the
                                        following email, telephone number or mailing address. Email: <a href="mailto:ask@PhantomPrintShop.com">ask@PhantomPrintShop.com</a>
                                        Telephone Number: +1 281-888-1784 Mailing Address: Phantom Print Shop, LLC, 6413 Allegheny
                                        St., Houston, Texas 77021
                                    </li>
                                </ul>
                            </p>



                        </p>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default MiddleFaq
